import axios from "axios";
import { Component } from "react";
import { setHeaderAuth } from "../utils";

export default class ApiAccount extends Component {
  static login = async (data) => {
    try {
      const result = await axios({
        url: "account/login",
        method: "post",
        data: data,
      });
      return result;
    } catch (error) {
      return error.response;
    }
  };

  static loginAD = async (data) => {
    try {
      const result = await axios({
        url: "account/loginAD",
        method: "post",
        data: data,
      });
      return result;
    } catch (error) {
      return error.response;
    }
  };

  
  static loginADFrontEnd = async (data) => {
    try {
      const result = await axios({
        url: "account/loginADFrontEnd",
        method: "post",
        data: data,
      });
      return result;
    } catch (error) {
      return error.response;
    }
  };

  static create = async (data) => {
    try {
      const result = await axios({
        url: "account/create",
        method: "post",
        data: data,
      });
      return result;
    } catch (error) {
      return error.response;
    }
  };

  static loginAs = async (data) => {
    try {
      const result = await axios({
        url: "account/adminLoginV2",
        method: "post",
        data: data,
      });
      return result;
    } catch (error) {
      return error.response;
    }
  };

  static loginWhExport = async (data) => {
    try {
      const result = await axios({
        url: "account/whLogin",
        method: "post",
        data: data,
      });
      return result;
    } catch (error) {
      return error.response;
    }
  };

  // static loginAs = async (data) => {
  //   try {
  //     const result = await axios({
  //       url: "account/adminLogin",
  //       method: "post",
  //       data: data,
  //     });
  //     return result;
  //   } catch (error) {
  //     return error.response;
  //   }
  // };
}
