import axios from "axios";
import { Component } from "react";
import { setHeaderAuth } from "../utils";

export default class ApiImage extends Component {
  static uploadImage = async (file) => {
    let data = new FormData();
    data.append('file', file, file.name);
    try {
      const result = await axios({
        url: "image/uploadImage",
        method: "post",
        data: data,
      });
      return result;
    } catch (error) {
      return error.response;
    }
  };

  static uploadImageBase64 = async (file) => {
    try {
      const result = await axios({
        url: "image/uploadImageBase64",
        method: "post",
        data: { file },
      });
      return result;
    } catch (error) {
      return error.response;
    }
  };

  static deleteImageCheckup = async (imageId) => {
    try {
      const result = await axios({
        url: `image/checkup/deleteImage?imageId=${imageId}`,
        method: "delete",

      });
      return result;
    } catch (error) {
      return error.response;
    }
  };

  
  static deleteImageWhExport = async (imageId) => {
    try {
      const result = await axios({
        url: `image/whExport/deleteImage?imageId=${imageId}`,
        method: "delete",

      });
      return result;
    } catch (error) {
      return error.response;
    }
  };

  static deleteImageShipment = async (imageId) => {
    try {
      const result = await axios({
        url: `image/shipment/deleteImage?imageId=${imageId}`,
        method: "delete",

      });
      return result;
    } catch (error) {
      return error.response;
    }
  };

  static deleteImageComment = async (data) => { 
    try {
      const result = await axios({
        url: `image/shipment/deleteImageComment`,
        method: "post",
        data: data
      });
      return result;
    } catch (error) {
      return error.response;
    }
  };

  static SaveJobImage = async (data) => {
    try {
      const result = await axios({
        url: "image/shipment/saveUploadImage",
        method: "post",
        data: data,
      });
      return result;
    } catch (error) {
      return error.response;
    }
  };

  static SaveCheckupImage = async (data) => {
    try {
      const result = await axios({
        url: "image/checkup/saveUploadImage",
        method: "post",
        data: data,
      });
      return result;
    } catch (error) {
      return error.response;
    }
  };

  static UpdateStatusCheckup = async (checkupId) => {
    try {
      const result = await axios({
        url: `image/checkup/updateStatus?checkupId=${checkupId}`,
        method: "post",
        // data: data,
      });
      return result;
    } catch (error) {
      return error.response;
    }
  };
  static UpdateStatusCheckupMultiple = async (data) => {
    try {
      const result = await axios({
        url: `image/checkup/updateStatusMultiple`,
        method: "post",
        data: data,
      });
      return result;
    } catch (error) {
      return error.response;
    }
  };
  

  static SaveExportDataImage = async (data) => {
    try {
      const result = await axios({
        url: "image/exportData/saveUploadImage",
        method: "post",
        data: data,
      });
      return result;
    } catch (error) {
      return error.response;
    }
  };

  static SaveExportData = async (data) => {
    try {
      const result = await axios({
        url: "image/exportData/saveData",
        method: "post",
        data: data,
      });
      return result;
    } catch (error) {
      return error.response;
    }
  };

  static FinishCheckup = async () => {
    try {
      const result = await axios({
        url: "image/checkup/finish",
        method: "post",
      });
      return result;
    } catch (error) {
      return error.response;
    }
  };
}
