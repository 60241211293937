import Swal from "sweetalert2";

const SwalLoad = () => {
    Swal.fire({
        heightAuto: false,
        icon: "info",
        html: "Loading... Please Wait",
        willOpen: () => {
            Swal.showLoading();
        },
        showConfirmButton: false,
        allowOutsideClick: false,
    });
};
const SwalErr = async (text) => {
    var res = Swal.fire({
        icon: "error",
        html: text,
        customClass: {
            confirmButton: "btn btn-success",
        },
        // buttonsStyling: false,
        allowOutsideClick: false,
        confirmButtonText: "ตกลง",
    });
    return res;
};
const SwalInfo = async (text) => {
    var res = Swal.fire({
        icon: "info",
        html: text,
        customClass: {
            confirmButton: "btn btn-info",
        },
        // buttonsStyling: false,
        allowOutsideClick: false,
        confirmButtonText: "ตกลง",
    });
    return res;
};
const SwalSuccess = async (text, { html = "" }) => {
    var res = await Swal.fire({
        icon: "success",
        html: html ? html : text,
        customClass: {
            confirmButton: "btn btn-success",
        },
        timer:2000,
        // buttonsStyling: false,
        allowOutsideClick: false,
        confirmButtonText: "ตกลง",
    });

    return res;
};

const SwalConfirm = async (text) => {
    var result = await Swal.fire({
        title: text,
        icon: "warning",
        customClass: {
            confirmButton: "btn btn-success ml-2",
            cancelButton: "btn btn-danger",
        },
        // buttonsStyling: false,
        confirmButtonText: "ยืนยัน",
        cancelButtonText: "ยกเลิก",
        reverseButtons: true,
        showCancelButton: true,
    });

    return result;
};

const SwalClose = () => {
    Swal.close();
};

const CSwl = {
    SwalLoad: SwalLoad,
    SwalErr: SwalErr,
    SwalClose: SwalClose,
    SwalSuccess: SwalSuccess,
    SwalConfirm: SwalConfirm,
    SwalInfo:SwalInfo
};

export default CSwl;
