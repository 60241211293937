export const msalConfig = {
    auth: {
        clientId: "f5eaf2a6-caf8-4d55-8506-be9d63d7ffb3", // server SCG2
        authority: "https://login.microsoftonline.com/organizations/", // server SCG 2
        //authority: `https://login.microsoftonline.com/661a6efb-de13-4ffc-b1e9-5228a679e87d`,
        redirectUri: process.env.REACT_APP_URL,
        navigateToLoginRequestUrl: false,
        validateAuthority: false,
    },
    cache: {
        cacheLocation: "sessionStorage", // This configures where your cache will be stored
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
};



export const loginRequest = {
    scopes: ["User.Read"],
};
