import { Link, Outlet, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { CalendarIcon } from '@heroicons/react/outline'
import { StaticImage } from "../../utils/image";
import ApiJob from "../../api/ApiJob";
import CSwl from "../../utils/alert";
import { useEffect, useState } from "react";
import { ConvertDate } from "../../utils";
import Modal from "../../conponents/Modal";
import moment from "moment";
import ApiUser from "../../api/ApiUser";
import { PencilAltIcon } from "@heroicons/react/solid";
import ModalNoButton from "../../conponents/ModalNoButton";
const ProfilePage = () => {
    const dispatch = useDispatch();
    const [profile, setProfile] = useState([]);
    useEffect(() => {
        getProfile();
    }, []);
    const [state, setstate] = useState({
        phone:""
    });
    const [isChangePhone, setIsChangePhone] = useState(false);
    const getProfile = async () => {
        CSwl.SwalLoad();
        const responseUser = await ApiUser.getProfile();
        if (responseUser.status == 200) {
            dispatch({ type: "set", user: responseUser.data });
            setProfile(responseUser.data)
            setstate({
                phone:responseUser.data.officerMobile
            })
            //navigate('/');
        }
        CSwl.SwalClose();
        console.log(responseUser.data)
    }

    const navigate = useNavigate();
    const userState = useSelector((state) => state.user);

    const changePhone = async (e) => {
        e.preventDefault();
        CSwl.SwalLoad();
        const response = await ApiUser.changePhone({
            "officer_code": userState.officerCode,
            "phone": state.phone,
        });
        if (response.status == 200) {
            //CSwl.SwalClose();
            const swal = await CSwl.SwalSuccess("เปลี่ยนเบอร์โทรศัพท์สำเร็จ", { html: "เปลี่ยนเบอร์โทรศัพท์สำเร็จ" });
            if (swal.isConfirmed) {
                setIsChangePhone(false);
                dispatch({ type: "set", user: response.data });
                setProfile(response.data);
                // setProfile(response.data)
            }
            //setstate({ ...state, password: "", showLogin: true, showChangePassword: false })
        } else {
            CSwl.SwalClose();
            setstate({ ...state, messageError: response.data });
            //CSwl.SwalErr(response.data);
        }
        // const user = UsersData.find(x => x.email == state.email && x.password == state.password);
        // if (!user) { setstate({ ...state, messageError: "Email or Password invalid", password: "" }); return false; }
        // else {
        //     setstate({ ...state, messageError: "" });
        //     dispatch({ type: "set", user: user });
        //     navigate('/');
        // }

    }

    return <div className="p-3 py-4">
        <div className="flex">
            <h1 className="text-2xl text-red">{`ข้อมูลผู้ใช้งาน`}</h1>
        </div>
        <div>
            <div className="p-2 bg-slate-100 rounded-lg">
                <div className="text-row">
                    <span className="left">ชื่อผู้ใช้: </span>
                    <span className="right">{profile.officerCode}</span>
                </div>
                <div className="text-row">
                    <span className="left">ชื่อ - นามสกุล: </span>
                    <span className="right">{profile.officerFirstnameTha}</span>
                </div>
                {/* <div className="text-row">
                    <span className="left">ประเภทรถ: </span>
                    <span className="right">{!profile.dsDetails ? profile.trucktype : profile.dsDetails[0]?.trucktype}</span>
                </div> */}
                <div className="text-row flex gap-x-1">
                    <span className="left">เบอร์โทรศัพท์: </span>
                    <span className="right">{profile.officerMobile}</span>
                    <PencilAltIcon onClick={() => {
                        setIsChangePhone(true)
                    }} className="h-5 w-5 text-red cursor-pointer" />
                </div>
            </div>
        </div>
        <div className="flex space-between gap-4 mt-4">
            <Link to="/" className="flex-1">
                <button className="btn-red flex-1 w-full mt-2 py-2">
                    <div className="flex items-center justify-center">
                        <span>กลับหน้าหลัก</span>
                    </div>
                </button>
            </Link>
        </div>
        <section className="min-h-screen pt-80px">
            <ModalNoButton isModal={isChangePhone} onClose={() => setIsChangePhone(false)}>
                <form onSubmit={changePhone}>
                    <div className="bg-white rounded flex flex-col items-center">
                        <div>
                            <h1 className="font-bold text-3xl">เปลี่ยนเบอร์โทรศัพท์</h1>
                        </div>
                        <input value={state.phone}
                            onChange={(e) => {
                                setstate({ ...state, phone: e.target.value })
                            }} required className="form-control w-72 mt-1" placeholder="กรุณากรอกเบอร์โทรศัพท์" />
                        {state.messageError && <label className="text-red">{state.messageError}</label>}
                        <div className="flex gap-x-2 w-full">
                            <button type="button" onClick={() => setIsChangePhone(false)} className="btn-gray flex-1 mt-2">ยกเลิก</button>
                            <button type="submit" className="btn-red flex-1 mt-2">บันทึก</button>
                        </div>

                    </div>
                </form>
            </ModalNoButton>
            <Outlet />
        </section>
    </div >
}

export default ProfilePage;