import { Disclosure } from "@headlessui/react";
import {
    BrowserRouter,
    Routes,
    Route,
    Link,
    useNavigate,

} from "react-router-dom";
import Job from "../job";
import { ChevronUpIcon, BriefcaseIcon } from '@heroicons/react/solid'
import { StaticImage } from "../../utils/image";
import { useEffect, useState } from "react";
import ApiJob from "../../api/ApiJob";
import CSwl from "../../utils/alert";
import { useDispatch, useSelector } from "react-redux";
import { CalendarIcon, CameraIcon } from "@heroicons/react/outline";
import { ConvertDate } from "../../utils";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import _, { split } from 'lodash';
import moment from "moment";
const ExportDataPage = () => {
    const searchWh = useSelector((state) => state.searchWh);
    const dispatch = useDispatch();
    const [state, setstate] = useState(searchWh);
    const [exportDataState, setexportDataState] = useState([]);
    useEffect(() => {
        //getExportData();
    }, []);

    const onSearch = (e) => {
        e.preventDefault();
        if(!state.PI_NO && !state.LOAD_DATE && !state.DP_NO && !state.EO_NO){
            CSwl.SwalErr("กรุณาระบุเงื่อนไขการค้นหาอย่างน้อย 1 ช่อง");
            return false;
        }
        getExportData();
    }

    const getExportData = async () => {
        CSwl.SwalLoad();
        dispatch({ type: "set", searchWh: state, LOAD_DATE: moment(state.LOAD_DATE).add(7, 'hours') });
        const response = await ApiJob.getExportData({
            ...state,
            LOAD_DATE: state.PI_NO ? null : moment(state.LOAD_DATE).add(7, 'hours')
        });
        if (response.status == 200) {
            const groupItems = _(response.data.data)
                .groupBy('loadDate')
                .map((value, key) => ({ loadDate: key, items: value }))
                .value();
            setexportDataState(groupItems);
        }
        CSwl.SwalClose();
    }


    return <>
        <nav class="flex bg-white text-gray border-b border-gray-200 py-3 px-5  mb-4" aria-label="Breadcrumb">
            <ol class="inline-flex items-center space-x-1 md:space-x-3">
                <li class="inline-flex items-center">
                    <Link to="/" className="text-red hover inline-flex items-center">
                        {/* <svg class="w-5 h-5 mr-2.5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path></svg> */}
                        ถ่ายรูปการบรรจุสินค้า
                    </Link>
                </li>
                {/* <li class="inline-flex items-center">
                    <Link to="/jobTimeline" className="text-gray-700 hover inline-flex items-center">
                        <svg class="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"></path></svg>
                        ไทม์ไลน์
                    </Link>
                </li>

                <li aria-current="page">
                    <div class="flex items-center">
                        <svg class="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"></path></svg>
                        <span class="text-red  ml-1 md:ml-2 font-medium">ถ่ายรูปการบรรจุสินค้า</span>
                    </div>
                </li> */}
            </ol>
        </nav>
        <div className="px-4">
            <div className="flex items-center">
                <CalendarIcon className="h-5 w-5 text-red mr-2" />
                <h1 className="text-2xl text-red">{ConvertDate(new Date(), 'วันddddที่ DD MMMM YYYY', 'th')}</h1>
            </div>
            {/* <div>
                <div className="p-2 bg-slate-100 rounded-lg">
                    <div className="text-row">
                        <span className="left">ชื่อผู้ใช้: </span>
                        <span className="right">{profile.officerCode}</span>
                    </div>
                    <div className="text-row">
                        <span className="left">ชื่อ - นามสกุล: </span>
                        <span className="right">{profile.drivername}</span>
                    </div>
                    <div className="text-row">
                        <span className="left">ประเภทรถ: </span>
                        <span className="right">{!profile.dsDetails ? profile.trucktype : profile.dsDetails[0]?.trucktype}</span>
                    </div>
                </div>
            </div> */}
            <form onSubmit={onSearch} className="mt-2 flex flex-col gap-y-2">
                <div className="flex items-center gap-x-2">
                    <label for="pino" className="block whitespace-nowrap w-20">PI No.</label>
                    <input type="text"
                        onChange={(e) => {
                            setstate({ ...state, PI_NO: e.target.value })
                        }}
                        value={state.PI_NO}
                        id="pino" className="form-control" placeholder="PI No." />
                </div>
                <div className="flex items-center gap-x-2">
                    <label for="pino" className="block whitespace-nowrap w-20">EO No.</label>
                    <input type="text" onChange={(e) => {
                        setstate({ ...state, EO_NO: e.target.value })
                    }} id="eono" value={state.EO_NO} className="form-control" placeholder="EO No." />
                </div>
                <div className="flex items-center gap-x-2">
                    <label for="pino" className="block whitespace-nowrap w-20">DP No.</label>
                    <input type="text" onChange={(e) => {
                        setstate({ ...state, DP_NO: e.target.value })
                    }} id="dpno" value={state.DP_NO} className="form-control" placeholder="DP No." />
                </div>
                <div className="flex items-center gap-x-2">
                    <label for="pino" className="block whitespace-nowrap w-16">Load Date</label>
                    {/* <input type="text" id="loaddate" required className="form-control" placeholder="Load Date" /> */}

                    <DatePicker dateFormat={"dd/MM/yyyy"} wrapperClassName="form-control flex-1" className="form-control flex-1" 
                    selected={state.LOAD_DATE ? moment(state.LOAD_DATE).toDate() : null} 
                    onChange={(date) => setstate({ ...state, LOAD_DATE: date })} />


                </div>
                <div className="flex justify-end">
                    <button type="submit" className="btn-blue w-20 mt-2">ค้นหา</button>
                </div>
            </form>
            <div className="flex flex-col gap-y-4">
                {
                    exportDataState.map((item, index) => {
                        return <div >
                            <label className="text-blue text-xl">Load Date : {ConvertDate(item.loadDate, 'DD/MM/yyyy')}</label>
                            <table class="mt-2 table-auto border-collapse border border-slate-500 w-full">
                                <thead>
                                    <tr>
                                        <th className="bg-blue text-white">PI No.</th>
                                        <th className="bg-blue text-white">Eo No.</th>
                                        <th className="bg-blue text-white">Dp No.</th>
                                        <th className="bg-blue w-10"></th>
                                    </tr>
                                </thead>
                                <tbody className="pb-2">
                                    {
                                        item.items.map((data, indexData) => {
                                            return <ExportDataItem data={data} />
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                    })
                }
            </div>
        </div>
    </>
}

const ExportDataItem = ({ data = {} }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const takePhoto = () => {
        dispatch({ type: "set", exportData: data });
        navigate(`/exportData/check?pino=${data.piNo}&eono=${data.eoNo}&docno=${data.docNo}`)
    }
    return <>
        <tr className={`${data.isUploaded ? "bg-blue-500" : ""}`}>
            <td className="text-center">{data.piNo}</td>
            <td className="text-center">{data.eoNo}</td>
            <td className="text-center">{data.docNo}</td>
            <td onClick={() => {
                takePhoto()
            }} className="text-center">
                <div className="flex items-center justify-center">
                    <CameraIcon
                        className={`h-5 w-5 text-blue`} />
                </div>
            </td>
        </tr>
    </>
}

export default ExportDataPage;