import { Disclosure } from "@headlessui/react";
import {
    BrowserRouter,
    Routes,
    Route,
    Link,
    useNavigate,

} from "react-router-dom";
import Job from "../job";
import { useDispatch, useSelector } from "react-redux";
import { ChevronUpIcon, BriefcaseIcon } from '@heroicons/react/solid'
import { StaticImage } from "../../utils/image";
import { useEffect, useState } from "react";
import ApiJob from "../../api/ApiJob";
import CSwl from "../../utils/alert";
const Home = () => {
    const navigate = useNavigate();
    const [instructions, setInstructions] = useState([]);
    const whExport = useSelector((state) => state.whExport);
    useEffect(() => {
        if(whExport){
            navigate('/exportData')
        }else{
            getInstruction();
        }
       
       
    }, []);

    const getInstruction = async () => {
        CSwl.SwalLoad();
        const response = await ApiJob.getInstruction();
        if (response.status == 200) {
            console.log(response.data)
            setInstructions(response.data.instructions);
        }
        CSwl.SwalClose();
    }

    return <div className="p-3 py-4">
        <h1 className="text-3xl">ข้อแนะนำและข้อปฏิบัติ</h1>
        {
            instructions.map((item, index) => {
                return <Disclosure as="div" className={index == 0 ? "" : "mt-2"} key={item.instrucId} defaultOpen>
                    {({ open }) => (
                        <>
                            <Disclosure.Button className={`flex w-full justify-between rounded-lg 
                        ${open ? 'rounded-bl-none rounded-br-none ' : ''}
                        bg-blue px-4 py-2 text-left text-sm font-medium text-white focus:outline-none focus-visible:ring focus-visible:ring-red-500 focus-visible:ring-opacity-75`}>
                                <span>{`${index + 1} ${item.instrucHd}`}</span>
                                <ChevronUpIcon
                                    className={`${open ? 'rotate-180 transform' : ''
                                        } h-5 w-5 text-purple-500`}
                                />
                            </Disclosure.Button>
                            <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-500 border border-gray-200 rounded-br-lg rounded-bl-lg">
                                {/* <div className="flex justify-center">
                                    <img className="object-contain h-40" src={StaticImage('/images/menu1.png')} />
                                </div>
                                <div className="mt-2">
                                    <span className="text-base"></span>
                                </div> */}
                                <div className="flex justify-center items-center">
                                {
                                    item.domainPath &&   <img className="h-60" src={process.env.REACT_APP_IMAGE + item.domainPath} />
                                }
                                </div>
                                <div dangerouslySetInnerHTML={{__html: item.instrucDesc}}></div>
                            </Disclosure.Panel>
                        </>
                    )}
                </Disclosure>
            })
        }
        <div className="flex space-between gap-4">
            <Link to="" className="flex-1">
                <button className="btn-red flex-1 w-full mt-2 py-2">
                    <div className="flex items-center justify-center">
                        <span>คำแนะนำ</span>
                    </div>
                </button>
            </Link>
            <Link to="/jobTimeline" className="flex-1">
                <button className="btn-red flex-1 w-full mt-2 py-2">
                    <div className="flex items-center justify-center">
                        {/* <BriefcaseIcon className="h-5 w-5 mr-2" /> */}
                        <span>งาน</span>
                    </div>
                </button>
            </Link>
        </div>
    </div>
}

export default Home;