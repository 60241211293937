import axios from "axios";
import { Component } from "react";
import { setHeaderAuth } from "../utils";

export default class ApiUser extends Component {
  static getProfile = async () => {
    await setHeaderAuth();
    try {
      const result = await axios({
        url: "user/profile",
        method: "get",
      });
      return result;
    } catch (error) {
      return error.response;
    }
  };

  static changePassword = async (data) => {
    const queryString = new URLSearchParams(data).toString();
    await setHeaderAuth();
    try {
      const result = await axios({
        url: "user/changePasswordNotAuth?"+queryString,
        method: "post",
      });
      return result;
    } catch (error) { 
      return error.response;
    }
  };

  static changePhone = async (data) => {
    const queryString = new URLSearchParams(data).toString();
    await setHeaderAuth();
    try {
      const result = await axios({
        url: "user/changePhone?"+queryString,
        method: "post",
      });
      return result;
    } catch (error) {
      return error.response;
    }
  };

  static comparePassword = async (data) => {
    const queryString = new URLSearchParams(data).toString();
    await setHeaderAuth();
    try {
      const result = await axios({
        url: "user/comparePassword?"+queryString,
        method: "post",
      });
      return result;
    } catch (error) {
      return error.response;
    }
  };


  static otpRequest = async (data) => {
    const queryString = new URLSearchParams(data).toString();
    await setHeaderAuth();
    try {
      const result = await axios({
        url: "user/otpRequest?"+queryString,
        method: "post",
      });
      return result;
    } catch (error) {
      return error.response;
    }
  };

  static otpRequestPhone = async (data) => {
    const queryString = new URLSearchParams(data).toString();
    await setHeaderAuth();
    try {
      const result = await axios({
        url: "user/otpRequestPhone?"+queryString,
        method: "post",
      });
      return result;
    } catch (error) {
      return error.response;
    }
  }

  static otpRequestRegister = async (data) => {
    const queryString = new URLSearchParams(data).toString();
    await setHeaderAuth();
    try {
      const result = await axios({
        url: "user/otpRequestRegister?"+queryString,
        method: "post",
      });
      return result;
    } catch (error) {
      return error.response;
    }
  };

  static resetPassword = async (data) => {
    const queryString = new URLSearchParams(data).toString();
    await setHeaderAuth();
    try {
      const result = await axios({
        url: "user/resetPassword?"+queryString,
        method: "post",
      });
      return result;
    } catch (error) {
      return error.response;
    }
  };
}
