const FooterLogin = () => {
    return <div className="flex justify-between fixed bottom-0 bg-white w-full p-2 px-4 flex-col sm:flex-row">
        <div>
            <span className="text-blue text-sm sm:text-lg">
                SCG PACKAGING PUBLIC COMPANY LIMITED
                1 SIAM CEMENT RD., BANGSUE, BANGKOK
                THAILAND TEL. +662 586 5555
            </span>
        </div>
        <div className="flex gap-x-1 items-center sm:items-start sm:gap-x-3 text-blue ">
            <a className="text-sm sm:text-lg whitespace-nowrap" target={"_blank"} href="https://www.scgpackaging.com/public/frontend/assets/file/cookie-notice/TH_PKG_Final_Cookie%20Policy_020420.pdf">COOKIE NOTICE</a>
            <div>|</div>
            <a className="text-sm sm:text-lg whitespace-nowrap" target={"_blank"} href="https://www.scgpackaging.com/th/legal">LEGAL</a>
            <div>|</div>
            <a className="text-sm sm:text-lg whitespace-nowrap" target={"_blank"} href="https://www.scgpackaging.com/th/privacy-notice">PRIVACY NOTICE</a>
            <div>|</div>
            <a className="text-sm sm:text-lg whitespace-nowrap" target={"_blank"} href="https://www.scgpackaging.com/th/take_down_notice">TAKE DOWN NOTICE</a>
        </div>
    </div>
}

export default FooterLogin

