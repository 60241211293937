import { Link, Outlet, useNavigate } from "react-router-dom";
import { StaticImage } from "../utils/image";
import { LogoutIcon, MenuIcon } from '@heroicons/react/outline'
import { useDispatch, useSelector } from "react-redux";
import { removeLocalStorage, setLocalStorage } from "../utils/localStorage";
import { Menu } from '@headlessui/react'
import { useState } from "react";
import Modal from "./Modal";
import CSwl from "../utils/alert";
import ApiUser from "../api/ApiUser";
import ModalNoButton from "./ModalNoButton";
import Swal from "sweetalert2";
import {
    AuthenticatedTemplate,
    UnauthenticatedTemplate,
    useMsal,
    useIsAuthenticated,
} from "@azure/msal-react";

const Layout = () => {
    const isAuthenticated = useIsAuthenticated();
    const { instance, accounts, inProgress } = useMsal();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const userState = useSelector((state) => state.user);
    const adminLogin = useSelector((state) => state.adminLogin);
    const whExport = useSelector((state) => state.whExport);
    const adminEmail = useSelector((state) => state.email);
    const adminOfficerCode = useSelector((state) => state.officerCode);
  
    const [isChangePassword, setisChangePassword] = useState(false);

    const [state, setstate] = useState({
        password: "",
        confirmPassword: "",
        newPassword: "",
    });

    // const logOut = async () => {
    //     dispatch({
    //         type: 'clear_all',
    //     });
    //     sessionStorage.clear();
    //     await removeLocalStorage('token');
    //     setTimeout(() => {
    //         navigate("/login", { replace: true })
    //     }, 200);
    // }

    const logOut = () => {
        if (isAuthenticated) {
            handleLogout(instance);
        } else {
            Swal.showLoading();
            localStorage.clear();
            sessionStorage.clear();
            setLocalStorage("token", "");
            dispatch({ type: "clear_all"});
            setTimeout(() => {
                Swal.close();
                navigate("/login", { replace: true })
            }, 2000);
        }
    };


    function handleLogout(instance) {
        sessionStorage.clear();
        localStorage.clear();
        setLocalStorage("token", "");
        dispatch({ type: "clear_all"});
        //sessionStorage.clear();
        instance.logoutRedirect().catch((e) => {
            setTimeout(() => {
                navigate("/login", { replace: true })
            }, 2000);
        });
    }

    const changePassword = async (e) => {
        e.preventDefault();
        CSwl.SwalLoad();
        if (state.newPassword !== state.confirmPassword) {
            setstate({ ...state, messageError: "ยืนยันรหัสผ่านไม่ตรงกัน" });
            CSwl.SwalClose();
            return false;
        }
        const response = await ApiUser.changePassword({
            "officer_code": userState.officerCode,
            "oldPassword": state.password,
            "newPassword": state.newPassword,
        });
        if (response.status == 200) {
            //CSwl.SwalClose();
            const swal = await CSwl.SwalSuccess("เปลี่ยนรหัสผ่านสำเร็จ", { html: "เปลี่ยนรหัสผ่านสำเร็จ" });
            if (swal.isConfirmed) {
                setisChangePassword(false);
            }
            //setstate({ ...state, password: "", showLogin: true, showChangePassword: false })
        } else {
            CSwl.SwalClose();
            setstate({ ...state, messageError: response.data });
            //CSwl.SwalErr(response.data);
        }
        // const user = UsersData.find(x => x.email == state.email && x.password == state.password);
        // if (!user) { setstate({ ...state, messageError: "Email or Password invalid", password: "" }); return false; }
        // else {
        //     setstate({ ...state, messageError: "" });
        //     dispatch({ type: "set", user: user });
        //     navigate('/');
        // }

    }


    return <>
        <div className="w-full bg-white py-2 px-4 shadow-slate-200 shadow-md flex items-center justify-between fixed top-0 z-10">
            <img className="object-contain h-16 w-24" src={StaticImage('/images/logoSCGP.svg')} />
            <div className="flex items-center relative">
                <span>{userState.email}</span>
                <Menu>
                    {({ open }) => (
                        <>
                            <Menu.Button>
                                <MenuIcon className="h-5 w-5 text-black ml-2" />
                            </Menu.Button>
                            {open && (
                                <div className="menu-container border border-gray-200">
                                    <Menu.Items>
                                    <Menu.Item>
                                            {({ active }) => (
                                                <div onClick={() => navigate('/profile')} className="flex gap-x-2">
                                                    <span>Profile</span>
                                                </div>
                                            )}
                                        </Menu.Item>
                                        <Menu.Item>
                                            {({ active }) => (
                                                <div onClick={() => setisChangePassword(true)} className="flex gap-x-2">

                                                    <span>เปลี่ยนรหัสผ่าน</span>
                                                </div>
                                            )}
                                        </Menu.Item>
                                        {
                                            whExport &&
                                            <>
                                                <Menu.Item>
                                                    {({ active }) => (
                                                        <div onClick={() => {
                                                            window.location.href = process.env.REACT_APP_URL_BACKEND + `#/login?username=${adminOfficerCode}`
                                                        }} className="flex gap-x-2">

                                                            <span>เข้าใช้งานระบบ E-DN</span>
                                                        </div>
                                                    )}
                                                </Menu.Item>
                                            </>
                                        }
                                        {
                                            adminLogin &&
                                            <>
                                                <Menu.Item>
                                                    {({ active }) => (
                                                        <div onClick={() => {
                                                            window.location.href = window.location.origin + `/login?username=${adminEmail}&officer_code=${adminOfficerCode}`
                                                            
                                                        }} className="flex gap-x-2">

                                                            <span>กลับไปเลือกทะเบียนรถ</span>
                                                        </div>
                                                    )}
                                                </Menu.Item>
                                                <Menu.Item>
                                                    {({ active }) => (
                                                        <div onClick={() => {
                                                            window.location.href = process.env.REACT_APP_URL_BACKEND + `#/login?username=${adminOfficerCode}`
                                                        }} className="flex gap-x-2">

                                                            <span>เข้าใช้งานระบบ E-DN</span>
                                                        </div>
                                                    )}
                                                </Menu.Item>
                                            </>
                                        }

                                        <Menu.Item>
                                            {({ active }) => (
                                                <div onClick={() => logOut()} className="flex gap-x-2">

                                                    <span>ออกจากระบบ</span>
                                                </div>
                                            )}
                                        </Menu.Item>
                                    </Menu.Items>
                                </div>
                            )}
                        </>
                    )}
                </Menu>
            </div>
        </div>
        <section className="min-h-screen pt-80px">
            <ModalNoButton isModal={isChangePassword} onClose={() => setisChangePassword(false)}>
                <form onSubmit={changePassword}>
                    <div className="bg-white rounded flex flex-col items-center">
                        <div>
                            <h1 className="font-bold text-3xl">Change Password</h1>
                        </div>
                        <input value={state.password}
                            onChange={(e) => {
                                setstate({ ...state, password: e.target.value })
                            }} required className="form-control w-72 mt-1" placeholder="Password" type="password" />
                        <input value={state.newPassword}
                            onChange={(e) => {
                                setstate({ ...state, newPassword: e.target.value })
                            }} required className={`${state.showLogin ? "hidden" : ""} form-control w-72 mt-1`} placeholder="New Password" type="password" />
                        <input value={state.confirmPassword}
                            onChange={(e) => {
                                setstate({ ...state, confirmPassword: e.target.value })
                            }} required className={`${state.showLogin ? "hidden" : ""} form-control w-72 mt-1`} placeholder="Confirm Password" type="password" />
                        {state.messageError && <label className="text-red">{state.messageError}</label>}
                        <div className="flex gap-x-2 w-full">
                            <button type="button" onClick={() => setisChangePassword(false)} className="btn-gray flex-1 mt-2">Cancel</button>
                            <button type="submit" className="btn-red flex-1 mt-2">Change Password</button>
                        </div>

                    </div>
                </form>
            </ModalNoButton>
            <Outlet />
        </section>
        {/* <div className="w-full bg-red py-2 px-4">
            <img className="object-contain h-16 w-24" src={StaticImage('/images/logoSCGPWhite.png')} />
            <div className="flex justify-between text-white ">
                <div className="flex-1">
                    <h3 className="font-bold">Thai Paper Co.,Ltd.</h3>
                    <div>
                        <span className="text-sm">4517 Washington Ave. Manchester, Kentucky 39495</span>
                    </div>
                    <div>
                        <span className="text-sm">0 2586 1000</span>
                    </div>
                </div>
                <div className="flex-1">
                    <h3 className="font-bold">Navigation</h3>
                    <div>
                        <span className="text-sm">Login</span>
                    </div>
                    <div>
                        <span className="text-sm">Privacy Notice</span>
                    </div>
                    <div>
                        <span className="text-sm">Cookie</span>
                    </div>
                </div>
            </div>
        </div>
        <div className="w-full bg-white py-2 px-4 pb-0 flex items-center">
            <h3 className="text-red "> © COPYRIGHT 2022 Thai Paper Co., Ltd. </h3>
        </div> */}
    </>
}

export default Layout;