import { Disclosure } from "@headlessui/react";
import { ChevronDownIcon, TrashIcon } from "@heroicons/react/outline";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import ApiCheckUp from "../../api/ApiCheckup";
import ApiImage from "../../api/ApiImage";
import { CheckFile, dataURLtoFile } from "../../utils";
import CSwl from "../../utils/alert";
import loadImage from "blueimp-load-image/js";
import { useDispatch, useSelector } from "react-redux";
const CheckNewHistoryPage = () => {
    const navigate = useNavigate();
    const profileState = useSelector((state) => state.profile);
    const [checkup, setCheckup] = useState({
        masterCheckups: [],
        tranCheckups: [],
        tranCheckupChecks: [],
    });
    useEffect(() => {
        getCheckup();
    }, []);

    const getCheckup = async () => {
        CSwl.SwalLoad();
        const response = await ApiCheckUp.getCheckupNew(profileState.business_type);
        if (response.status == 200) {
            setCheckup({
                masterCheckups: response.data.masterCheckups,
                tranCheckups: response.data.tranCheckups,
                tranCheckupChecks: response.data.tranCheckupChecks
            });
        }
        CSwl.SwalClose();
    }

    const titelList = [];
    checkup.masterCheckups.map((item, index) => {
        if (titelList.indexOf(item.title) === -1) {
            titelList.push(item.title);
            return item.title;
        }
        return null;
    })

    const finishCheckup = async () => {
        //console.log("batchItems::",batchItems)
        CSwl.SwalLoad();
        try {
            const response = await ApiCheckUp.finishCheckupCheck(profileState.business_type);
            if (response.status == 200) {
                navigate('/jobTimeline');
            }
            else {
                CSwl.SwalErr(`${response.data}`);
            }

        } catch (error) {
            const { data } = error.response;
            CSwl.SwalErr(data.message)
        }
    }

    const selectAll = async () => {
        const response = await ApiImage.UpdateStatusCheckupMultiple({
            checkupId: checkup.masterCheckups.filter(x => !(checkup.tranCheckupChecks.map(y => y.checkupId).includes(x.id))).map(x => x.id)
        });
        if (response.status == 200) {
            setCheckup({
                masterCheckups: [],
                tranCheckups: [],
                tranCheckupChecks: [],
            })
            getCheckup();
        }
    }

    return <div className="pb-5">
        <nav class="flex bg-white text-gray border-b border-gray-200 py-3 px-5  mb-4" aria-label="Breadcrumb">
            <ol class="inline-flex items-center space-x-1 md:space-x-3">
                <li class="inline-flex items-center">
                    <Link to="/" className="text-gray-700 hover:text-gray-900 inline-flex items-center">
                        <svg class="w-5 h-5 mr-2.5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path></svg>
                        หน้าแรก
                    </Link>
                </li>
                <li class="inline-flex items-center">
                    <Link to="/jobTimeline" className="text-gray-700 hover:text-gray-900 inline-flex items-center">
                        <svg class="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"></path></svg>
                        ไทม์ไลน์
                    </Link>
                </li>
                <li aria-current="page">
                    <div class="flex items-center">
                        <svg class="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"></path></svg>
                        <span class="text-red  ml-1 md:ml-2 font-medium">ตรวจความพร้อม</span>
                    </div>
                </li>
            </ol>
        </nav>
        <div className="px-3">
            <div className="flex mt-3 gap-x-2 mb-2">
                <button className="btn-blue ml-2" onClick={() => { 
                    navigate('/jobTimeline')
                 }}>ย้อนกลับ</button>
            </div>
            {
                titelList.map((title, index) => {
                    return (
                        <>
                            <div className="flex">
                                <h1 className="text-2xl text-red">{title}</h1>
                            </div>
                            {checkup.masterCheckups.filter(x => x.title == title).map((item, index) => {
                                return (
                                    <div>
                                        <CheckItem setCheckup={setCheckup} getCheckup={getCheckup} data={item} key={item.id}
                                         title={item.detail} 
                                         checkupId={item.id}
                                            checkupItem={checkup.tranCheckups.filter((s) => { return s.checkupId == item.id })}
                                            checkupStatusItem={checkup.tranCheckupChecks.filter((s) => { return s.checkupId == item.id })}
                                        />
                                    </div>
                                )
                            })
                            }
                        </>
                    )
                })
            }
           
            {/* <div className="flex">
                <h1 className="text-2xl text-red">ตรวจสอบความพร้อมพนักงานขับรถ</h1>
            </div>
            <div>
                {checkup.masterCheckups.filter(x => x.title == "ตรวจความพร้อมพนักงานขับรถ").map((item, index) => {
                    return <CheckItem key={item.id} title={item.detail} />
                })}
            </div>
            <div className="flex mt-4">
                <h1 className="text-2xl text-red">ตรวจสภาพรถ</h1>
            </div>
            <div>
                {checkup.masterCheckups.filter(x => x.title == "ตรวจสภาพรถ").map((item, index) => {
                    return <CheckItem key={item.id} title={item.detail} />
                })}
            </div>
            <div className="flex mt-4">
                <h1 className="text-2xl text-red">อุปกรณ์ Safety</h1>
            </div>
            <div>
                {checkup.masterCheckups.filter(x => x.title == "อุปกรณ์ Safety").map((item, index) => {
                    return <CheckItem key={item.id} title={item.detail} />
                })}
            </div> */}
        </div>
    </div>
}

const CheckItem = ({ title, checkupId, checkupItem, data, checkupStatusItem ,getCheckup,setCheckup}) => {
    const [photosDefault, setphotos] = useState(checkupItem);
    const [checkupDefault, setcheckupDefault] = useState(checkupStatusItem);
    const navigate = useNavigate();
    const [file, setfile] = useState("");
    const uploadFile = async (e) => {
        if (e.target.files.length > 0) {
            const file = e.target.files[0];

            if (await CheckFile({ file: file, size: 10, type: ['image/gif', 'image/jpeg', 'image/png'] })) {
                CSwl.SwalLoad();
                loadImage(
                    file,
                    img => {
                        console.log(file);
                        var base64data = img.toDataURL(`image/jpeg`);
                        var fileNew = dataURLtoFile(base64data, file.name);
                        setfile(fileNew);
                        uploadFileApi(fileNew);
                        e.target.value = null;
                    },
                    { orientation: true, canvas: true }
                );

                // console.log("image::",image)
                //setfile(image);

                CSwl.SwalClose();
            }
        }
    }

    const uploadFileApi = async (file) => {
        const response = await ApiImage.uploadImage(file);
        if (response.status == 200) {
            const responseSaveImage = await ApiImage.SaveCheckupImage({
                "checkupId": checkupId,
                "photoName": response.data.filename,
                "photoThumbnailName": response.data.thumbnainlname
            });
            if (responseSaveImage.status == 200) {
                setphotos([...photosDefault,
                {
                    id: responseSaveImage.data.imgid,
                    photoThumbnailName: responseSaveImage.data.thumbnailName,
                    checkupId: checkupId
                }])
                setCheckup({
                    masterCheckups: [],
                    tranCheckups: [],
                    tranCheckupChecks: [],
                })
                getCheckup();
            }
        }
    }


    const checkupApi = async (file) => {
        const response = await ApiImage.UpdateStatusCheckup(checkupId);
        if (response.status == 200) {
            getCheckup();
            // const responseSaveImage = await ApiImage.SaveCheckupImage({
            //     "checkupId": checkupId,
            //     "photoName": response.data.filename,
            //     "photoThumbnailName": response.data.thumbnainlname
            // });
            // if (responseSaveImage.status == 200) {
            //     setphotos([...photosDefault,
            //     {
            //         id: responseSaveImage.data.imgid,
            //         photoThumbnailName: responseSaveImage.data.thumbnailName,
            //         checkupId: checkupId
            //     }])
            // }
        }
    }


    const deleteImage = async (id) => {
        CSwl.SwalLoad();

        const response = await ApiImage.deleteImageCheckup(id);
        if (response.status == 200) {
            setphotos(photosDefault.filter(x => x.id != id))
            CSwl.SwalClose();
        }
        else {
            CSwl.SwalClose();
        }
    }

    useEffect(() => {
        console.log('data::', checkupDefault)
    }, [checkupDefault]);



    return <Disclosure defaultOpen>
        {({ open }) => (
            <>
                <Disclosure.Button className={`flex w-full justify-between 
            ${open ? 'rounded-bl-none rounded-br-none ' : ''}
            border border-gray-200 px-4 py-2 text-left text-sm font-medium focus:outline-none focus-visible:ring focus-visible:ring-red-500 focus-visible:ring-opacity-75`}>
                    <div className="flex gap-x-2">
                        <input disabled onClick={(e) => {
                            e.stopPropagation();
                        }} onChange={(e) => {
                            checkupApi(e.target.checked)
                            // setstate({ ...state, isCheckupDriver: e.target.checked })
                        }} id="default-checkbox" type="checkbox" defaultChecked={checkupDefault?.length > 0} value={""} class="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                        {/* <label for="default-checkbox" class="font-medium text-gray-900 dark:text-gray-300">{title}</label> */}
                        <span className="text-bold">{title} {data.isMajor && <span className="text-red-500">*</span>}</span>
                    </div>
                    {
                        data.maxPhoto > 0 && <ChevronDownIcon
                            className={`${open ? 'rotate-180 transform' : ''
                                } h-5 w-5 text-purple-500`}
                        />
                    }

                </Disclosure.Button>

                {data.maxPhoto > 0 && <Disclosure.Panel 
                className="px-4 pt-4 pb-2 text-sm text-gray-500 border border-gray-200 rounded-br-lg rounded-bl-lg gap-y-2 flex flex-col">
                    
                    <div class="flex w-full items-center justify-center bg-grey-lighter flex flex-wrap gap-2">
                        {
                            photosDefault.map((item, index) => {
                                return <div className="relative min-w-[100px] max-w-[100px]">
                                    <img src={process.env.REACT_APP_IMAGE + item.photoThumbnailName} />
                                   
                                </div>
                            })
                        }

                    </div>
                </Disclosure.Panel>}


            </>
        )}
    </Disclosure>
}

export default CheckNewHistoryPage;