import {
    AuthenticatedTemplate,
    UnauthenticatedTemplate,
    useMsal,
    useIsAuthenticated,
} from "@azure/msal-react";
import { Link, Outlet, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setLocalStorage } from "../../utils/localStorage";
import Swal from "sweetalert2";
import { useEffect } from "react";
const  Logout = () => {
    const isAuthenticated = useIsAuthenticated();
    const { instance, accounts, inProgress } = useMsal();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const logOut = () => {
        if (isAuthenticated) {
            handleLogout(instance);
        } else {
            Swal.showLoading();
            localStorage.clear();
            sessionStorage.clear();
            setLocalStorage("token", "");
            dispatch({ type: "set", user: {}, token: "" });
            setTimeout(() => {
                Swal.close();
                navigate("/login", { replace: true })
            }, 2000);
        }
    };

    function handleLogout(instance) {
        sessionStorage.clear();
        localStorage.clear();
        setLocalStorage("token", "");
        dispatch({ type: "set", user: {}, token: "" });
        //sessionStorage.clear();
        instance.logoutRedirect().catch((e) => {
            setTimeout(() => {
                navigate("/login", { replace: true })
            }, 2000);
        });
    }

    useEffect(() => {
        logOut();
    }, []);
    return <></>
}

export default Logout