import axios from "axios";
import { Component } from "react";
import { setHeaderAuth } from "../utils";

export default class ApiJob extends Component {
  static getInstruction = async () => {
    try {
      await setHeaderAuth();
      const result = await axios({
        url: "job",
        method: "get",
      });
      return result;
    } catch (error) {
      return error.response;
    }
  };
  static getExportData = async (data) => {
    try {
      await setHeaderAuth();
      const result = await axios({
        url: "job/ExportData",
        method: "post",
        data:data
      });
      return result;
    } catch (error) {
      return error.response;
    }
  };
  
  static getMasterFleet = async () => {
    try {
      await setHeaderAuth();
      const result = await axios({
        url: "job/masterFleet",
        method: "get",
      });
      return result;
    } catch (error) {
      return error.response;
    }
  };
  static getJob = async ({ type = "Job", take = 5, state = 1, shipment = "", truck = "" }) => {
    try {
      const queryString = new URLSearchParams({ type, take, state, shipment, truck }).toString();
      await setHeaderAuth();
      const result = await axios({
        url: "job/getJob?" + queryString,
        method: "get",
      });
      return result;
    } catch (error) {
      console.log(error)
      return error.response;
    }
  };
  static getTruckType = async () => {
    try {
      const result = await axios({
        url: "job/masterTruck",
        method: "get",
      });
      return result;
    } catch (error) {
      console.log(error)
      return error.response;
    }
  };
  static getSignature = async ({ shipto = "" }) => {
    try {
      const queryString = new URLSearchParams({ shipto }).toString();
      await setHeaderAuth();
      const result = await axios({
        url: "job/getSignature?" + queryString,
        method: "get",
      });
      return result;
    } catch (error) {
      console.log(error)
      return error.response;
    }
  };
  static getBarcode = async ({ ShipmentNo = "Job", SaleOrg = 10 }) => {
    try {
      const queryString = new URLSearchParams({ ShipmentNo, SaleOrg }).toString();
      await setHeaderAuth();
      const result = await axios({
        url: "job/barcode?" + queryString,
        method: "get",
      });
      return result;
    } catch (error) {
      console.log(error)
      return error.response;
    }
  };
  static confirmShipment = async (shipmentNo) => {
    try {
      const queryString = new URLSearchParams({ shipmentNo }).toString();
      await setHeaderAuth();
      const result = await axios({
        url: "job/confirmShipment?" + queryString,
        method: "post",
      });
      return result;
    } catch (error) {
      console.log(error)
      return error.response;
    }
  };
  static booking = async (data) => {
    try {
      const queryString = new URLSearchParams({
        booking_status: data.booking_status,
        cancel_reson: data.cancel_reson,
        not_booking_reason: data.not_booking_reason,
        fleet_id: data.fleet_id ?? 0
      }).toString();
      await setHeaderAuth();
      const result = await axios({
        url: "job/booking?" + queryString,
        method: "post"
      });
      return result;
    } catch (error) {
      console.log(error)
      return error.response;
    }
  };
  static finishWH = async (shipmentNo, remark, dpno) => {
    try {
      const queryString = new URLSearchParams({ shipmentNo, remark, dpno }).toString();
      await setHeaderAuth();
      const result = await axios({
        url: "job/finishWh?" + queryString,
        method: "post",
      });
      return result;
    } catch (error) {
      console.log(error)
      return error.response;
    }
  };
  static finishDelivery = async (data) => {
    try {
      await setHeaderAuth();
      const result = await axios({
        url: "job/finishDelivery",
        method: "post",
        data: data
      });
      return result;
    } catch (error) {
      return error.response;
    }
  };
  static finishAdmin = async (data) => {
    try {
      await setHeaderAuth();
      const result = await axios({
        url: "job/finishAdmin",
        method: "post",
        data: data
      });
      return result;
    } catch (error) {
      return error.response;
    }
  };
  static getDataEDP = async (data) => {
    try {
      await setHeaderAuth();
      const result = await axios({
        url: "job/GetEDPRoleItem",
        method: "post",
        data: data
      });
      return result;
    } catch (error) {
      return error.response;
    }
  };

  static deleteShipment = async (data) => {
    try {
      const queryString = new URLSearchParams(data).toString();
      await setHeaderAuth();
      const result = await axios({
        url: "job/DeleteShipment?" + queryString,
        method: "post",
        data: data
      });
      return result;
    } catch (error) {
      return error.response;
    }
  };
  // static reportShipment = async (shipmentNo, shipTo) => {
  //   try {
  //     const queryString = new URLSearchParams({ shipmentNo, shipTo }).toString();
  //     await setHeaderAuth();
  //     const result = await axios({
  //       url: "report/generateReportByShipment?" + queryString,
  //       method: "get",
  //     });
  //     return result;
  //   } catch (error) {
  //     console.log(error)
  //     return error.response;
  //   }
  // };
  static reportShipment = async (shipmentNo, shipTo) => {
    try {
      const queryString = new URLSearchParams({ shipmentNo, shipTo }).toString();
      await setHeaderAuth();
      const result = await axios({
        url: process.env.REACT_APP_URL_LMS_SERVICE + "EDN/GenerateReportByShipment?" + queryString,
        method: "get",
      });
      return result;
    } catch (error) {
      console.log(error)
      return error.response;
    }
  };
  static updateCompleteDp = async (reason) => {
    try {
      await setHeaderAuth();
      const result = await axios({
        url: `job/updateCompleteDp?reason=${reason}`,
        method: "post",
      });
      return result;
    } catch (error) {
      console.log(error)
      return error.response;
    }
  };
  static saveBatchItem = async (data) => {
    try {
      await setHeaderAuth();
      const result = await axios({
        url: "job/saveBatch",
        method: "post",
        data: data
      });
      return result;
    } catch (error) {
      console.log(error)
      return error.response;
    }
  };

  static saveSuggestOrder = async (data) => {
    try {
      await setHeaderAuth();
      const result = await axios({
        url: "job/saveSuggestOrder",
        method: "post",
        data: data
      });
      return result;
    } catch (error) {
      console.log(error)
      return error.response;
    }
  };

  static refreshBatchItem = async (data) => {
    try {
      const queryString = new URLSearchParams(data).toString();
      await setHeaderAuth();
      const result = await axios({
        url: "job/refreshBatchItem?" + queryString,
        method: "post",
      });
      return result;
    } catch (error) {
      console.log(error)
      return error.response;
    }
  };

  static refreshBatchItemByMat = async (data) => {
    try {
      const queryString = new URLSearchParams(data).toString();
      await setHeaderAuth();
      const result = await axios({
        url: "job/refreshBatchItemsByMat?" + queryString,
        method: "post",
      });
      return result;
    } catch (error) {
      console.log(error)
      return error.response;
    }
  };
}
